import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../core/notifications/notification.service';
import {DocumentOrigins, DocumentsService} from '../documents.service';
import { selectAuthToken, selectAuthControlledUser } from '../../../core/auth/auth.selectors';
import { AppState } from 'src/app/core/core.state';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';
import {Component, Input, OnInit, Output, EventEmitter, SimpleChanges} from '@angular/core';
import { PDFSource } from 'ng2-pdf-viewer';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {BackButtonService} from "../../../core/utils/back-button.service";
import {DocumentDetails, PDFInfo} from "../documents.models";
import {api} from "../../../core/api/api.vars";

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {

  @Input() title = '';
  @Input() subtitle = '';

  @Input() actionButtons: {
    label: string | null,
    icon?: string | null,
    color: string | null,
    actionId: string | null,
    isDownloadBtn?: boolean,
    isBottomBtn?: boolean
  }[] = [];

  @Input() createdAt = '';
  @Input() element;
  @Input() documentDetails: DocumentDetails;
  @Input() origin?: DocumentOrigins = null;

  @Input() statusIcon = 'check_circle_outline';
  @Input() statusLabel = 'Payée';
  @Input() statusType: 'success' | 'error' | 'warning' | 'none' = 'success';
  @Input() pdfUrl = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';

  @Output() onBack: EventEmitter<void> = new EventEmitter();
  @Output() onAction: EventEmitter<string> = new EventEmitter();


  pdfInitData: PDFSource = null;

  pdfList: Array<PDFInfo> = [];

  loading$ = new BehaviorSubject(false);


  constructor(
    private store: Store<AppState>,
    private documentService: DocumentsService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private backBtnService: BackButtonService
  ) { }

  ngOnInit(): void {
    this.loadPdfData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pdfUrl) {
        this.loadPdfData()
      }

    if (changes.documentDetails) {
      this.loadPdfList()
    }
  }

  getTopActionButtons() {
    return this.actionButtons.filter(btn => !btn.isBottomBtn);
  }

  getBottomActionButtons() {
    return this.actionButtons.filter(btn => btn.isBottomBtn);
  }

  loadPdfList() {
    if (!!this.documentDetails) {
      this.pdfList = [];
      // On fait ça seulement si on a plusieurs pdf dans le classseur
      if (this.documentDetails.fichiers.length > 1) {
        this.store.select(selectAuthToken).pipe(
            withLatestFrom(this.store.select(selectAuthControlledUser)),
            take(1)
        ).subscribe(([data, controlledUser]) => {
          if (
              !!data &&
              !!data.access_token
          ) {
            const tmpPdfData = {
              httpHeaders: {
                Authorization: `Bearer ${data.access_token}`,
                'x-ac-userid': !!controlledUser && !!controlledUser.id ? `${controlledUser.id}` : ''
              }
            };

            this.documentDetails.fichiers.forEach(fichier => {
              this.pdfList.push({
                intitule: fichier.intitule,
                pdfSource: {
                  ...tmpPdfData,
                  url: api.endpoints.documents.FICHIER_CLASSEUR
                      .replace('{{id_file}}', `${this.documentDetails.id_file}`)
                      .replace('{{origin}}', `${this.origin}`)
                      .replace('{{fichier}}', `${fichier.numero}`)
                }
              })
            })
          }
        })

      }
    }
  }


  triggerDownload() {
    this.loading$.next(true);
    this.documentService.downloadByUrl(this.pdfUrl, 'application/pdf', this.title).subscribe(
      () => {
        this.loading$.next(false);
      },
      () => {
        this.loading$.next(false);
        this.notificationService.error(
          this.translateService.instant('ac.global.unexpected_exception')
        );
      },
      () => {
        this.loading$.next(false);
      }
    )
  }

  downloadFichierClasseur(url) {
    this.documentService.downloadByUrl(url, 'application/pdf', this.title).subscribe(
        () => {},
        () => {
          this.notificationService.error(
              this.translateService.instant('ac.global.unexpected_exception')
          );
        }
    )
  }

  downloadFichierDossier(dossier: string, fichier: string) {
    this.documentService.downloadByUrl(
        api.endpoints.documents.FICHIER_DOSSIER_CLASSEUR
            .replace('{{origin}}', `${this.origin}`)
            .replace('{{dossier}}', dossier)
            .replace('{{id_file}}', `${this.documentDetails.id_file}`)
            .replace('{{fichier}}', fichier),
        null,
        fichier
    ).subscribe(
        () => {},
        () => {
          this.notificationService.error(
              this.translateService.instant('ac.global.unexpected_exception')
          );
        }
    )
  }

  getNombreFichiersDossiers() {
    let count = 0;

    this.documentDetails?.dossiers.forEach(dosssier => count += dosssier.fichiers.length);
    return count;
  }



  private loadPdfData() {
    this.store.select(selectAuthToken).pipe(
      withLatestFrom(this.store.select(selectAuthControlledUser)),
      take(1)
    ).subscribe(([data, controlledUser]) => {
      if (
        !!data &&
        !!data.access_token
      ) {
        this.pdfInitData = {
          url: this.pdfUrl,
          // withCredentials: true,
          httpHeaders: {
            Authorization: `Bearer ${data.access_token}`,
            'x-ac-userid': !!controlledUser && !!controlledUser.id ? `${controlledUser.id}` : ''
          }
        }
      }
    })
  }

  back() {
    this.onBack.emit();
  }
}

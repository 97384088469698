import { NotificationsState } from './../features/home/notifications/core/notifications.models';
import { DashboardState } from './../features/home/dashboard/core/dashboard.models';
import { PatrimoniesState } from './../features/home/patrimony/core/patrimonies.models';
import { ContractsState } from './../features/home/contracts/core/contracts.models';
import { UsersState } from '../features/home/administration/core/users.models';
import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { environment } from '../../environments/environment';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { usersReducer } from '../features/home/administration/core/users.reducers';
import { contractsReducer } from '../features/home/contracts/core/contracts.reducers';
import { accountsReducer } from "../features/login/core/accounts/accounts.reducer";
import { AccountsState } from "../features/login/core/accounts/accounts.state";
import { patrimoniesReducer } from '../features/home/patrimony/core/patrimonies.reducers';
import { InvoicesState } from "../features/home/invoices/core/invoices.models";
import { invoicesReducer } from "../features/home/invoices/core/invoices.reducers";
import { OperationsState } from "../features/home/operations/core/operations.models";
import { operationsReducer } from "../features/home/operations/core/operations.reducers";
import { myUsersReducer } from "../features/home/user-management/core/my-users.reducers";
import { MyUsersState } from "../features/home/user-management/core/my-users.models";
import { dashboardReducer } from '../features/home/dashboard/core/dashboard.reducers';
import {SearchReducer} from "../features/home/search/core/search.reducers";
import {SearchState} from "../features/home/search/core/search.models";
import { notificationsReducer } from '../features/home/notifications/core/notifications.reducers';
import {TiersState} from "../features/home/tiers/core/tiers.models";
import { tiersReducer } from '../features/home/tiers/core/tiers.reducers';
import {ContactFormState} from "../features/home/contact-form/core/contact-form.models";
import { contactFormReducer } from '../features/home/contact-form/core/contact-form.reducers';
import {DocumentsState} from "../shared/documents/documents.models";
import {documentsReducer} from "../shared/documents/documents.reducers";
import {enveloppeReducer} from "../features/enveloppe/core/enveloppe.reducers";
import {EnveloppeState} from "../features/enveloppe/core/enveloppe.models";

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  router: routerReducer,
  users: usersReducer,
  myUsers: myUsersReducer,
  contracts: contractsReducer,
  invoices: invoicesReducer,
  accounts: accountsReducer,
  patrimonies: patrimoniesReducer,
  operations: operationsReducer,
  documents: documentsReducer,
  dashboard: dashboardReducer,
  search: SearchReducer,
  notifications: notificationsReducer,
  tiers: tiersReducer,
  contactForms: contactFormReducer,
  enveloppe: enveloppeReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
  metaReducers.unshift(debug);
}

export const selectAuthState =
  createFeatureSelector<AuthState>('auth');

export const selectUsersState =
  createFeatureSelector<UsersState>('users');
export const selectMyUsersState =
  createFeatureSelector<MyUsersState>('myUsers');


export const selectContractsState = createFeatureSelector<ContractsState>('contracts');
export const selectInvoicesState = createFeatureSelector<InvoicesState>('invoices');

export const selectRouterState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectAccountsState =
  createFeatureSelector<AccountsState>('accounts');

export const selectPatrimoniesState =
  createFeatureSelector<PatrimoniesState>('patrimonies');

export const selectOperationsState =
  createFeatureSelector<OperationsState>('operations');

export const selectDocumentsState =
    createFeatureSelector<DocumentsState>('documents');

export const selectDashboardState =
  createFeatureSelector<DashboardState>('dashboard');

export const selectSearchState =
  createFeatureSelector<SearchState>('search');

export const selectNotificationsState =
  createFeatureSelector<NotificationsState>('notifications');

export const selectTiersState = createFeatureSelector<TiersState>('tiers');
export const selectContactFormState = createFeatureSelector<ContactFormState>('contactForms');
export const selectEnveloppeState = createFeatureSelector<EnveloppeState>('enveloppe');

export interface AppState {
  auth: AuthState;
  router: RouterReducerState<RouterStateUrl>;
  users: UsersState;
  myUsers: MyUsersState,
  contracts: ContractsState,
  invoices: InvoicesState,
  accounts: AccountsState,
  patrimonies: PatrimoniesState,
  operations: OperationsState,
  documents: DocumentsState,
  dashboard: DashboardState,
  search: SearchState,
  notifications: NotificationsState,
  tiers: TiersState,
  contactForms: ContactFormState,
  enveloppe: EnveloppeState
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './root/app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { UiLibModule } from '@alpes-controles/ui-lib';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {EffectsModule} from "@ngrx/effects";
import {EnveloppeEffects} from "./features/enveloppe/core/enveloppe.effects";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpErrorInterceptor} from "./core/http-interceptors/http-error.interceptor";
import {HttpAuthInterceptor} from "./core/http-interceptors/auth.interceptor";

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // core
    CoreModule,
    SharedModule,

    // app routing
    AppRoutingModule,

    // UI library
    UiLibModule,

    // Material Icons
    MatIconModule,
    EffectsModule.forFeature([
        EnveloppeEffects
        ])
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer){
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    matIconRegistry.addSvgIcon('file-pdf', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/pdf.svg'));
    matIconRegistry.addSvgIcon('cert', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/CERT.svg'));
    matIconRegistry.addSvgIcon('csps', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/CSPS.svg'));
    matIconRegistry.addSvgIcon('ctc', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/CTC.svg'));
    matIconRegistry.addSvgIcon('dim', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/DIM.svg'));
    matIconRegistry.addSvgIcon('env', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/ENV.svg'));
    matIconRegistry.addSvgIcon('exp', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/EXP.svg'));
    matIconRegistry.addSvgIcon('linkedin2', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/linkedin2.svg'));
    matIconRegistry.addSvgIcon('plagiarism', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/plagiarism.svg'));
  }

}

import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from "@angular/core";
import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Directive({
  selector: 'input[toLowercase]',
})
export class LowerCaseInputDirective {

  lastValue: string;

  @HostListener('input', ['$event']) input($event: InputEvent) {
    const target = $event.target as HTMLInputElement;
    const start = target.selectionStart;


    target.value = target.value.toLowerCase();
    target.setSelectionRange(start, start);

    if (!this.lastValue || (this.lastValue && target.value.length > 0 && this.lastValue !== target.value)) {
      this.lastValue = target.value;
      const evt = new Event("input", {
        bubbles: true,
        cancelable: false
      });
      $event.target.dispatchEvent(evt);
    }
  }

}
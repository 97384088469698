import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  EnveloppeFormulaireBtnSignAdminComponent
} from "../../features/enveloppe/components/formulaires/enveloppe-formulaire-btn-sign-admin/enveloppe-formulaire-btn-sign-admin.component";

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

  @Output()
  confirm: EventEmitter<any> = new EventEmitter();

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<EnveloppeFormulaireBtnSignAdminComponent>,
  ) {

  }

  clickConfirm() {
    this.dialogRef.close(true);
    this.confirm.emit();
  }
}

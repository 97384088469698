<button
        class="link-button"
        *ngIf="!!leftButtonLabel"
        (click)="onLeftButtonClick.emit()"
        [disabled]="(leftButtonLoading$ | async) || leftButtonDisabled"
        mat-button>
    {{ leftButtonLabel | translate }}
    <mat-icon *ngIf="(leftButtonLoading$ | async)" matPrefix><mat-spinner diameter="20"></mat-spinner></mat-icon>
</button>
<button
        [class.green-btn]="!rightButtonDisabled"
        *ngIf="!!rightButtonLabel"
        (click)="onRightButtonClick.emit()"
        [disabled]="(rightButtonLoading$ | async) || rightButtonDisabled"
        mat-raised-button>
    {{ rightButtonLabel | translate }}
    <mat-icon *ngIf="(rightButtonLoading$ | async)" matPrefix><mat-spinner diameter="20"></mat-spinner></mat-icon>
</button>
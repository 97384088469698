import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {EnveloppeService} from "./enveloppe.services";
import {
  demandeContactEnveloppeExpiree,
  demandeContactEnveloppeExpireeError,
  demandeContactEnveloppeExpireeSuccess,
  loadEnveloppeAction,
  loadEnveloppeErrorAction,
  loadEnveloppeSuccessAction, setFichierOpened, setFichierRead,
  setSessionUid,
  updateForms,
  updateFormsError,
  updateFormsSuccess
} from "./enveloppe.actions";
import {of, switchMap} from "rxjs";
import {catchError, filter, map, take, tap, withLatestFrom} from "rxjs/operators";
import {selectEnveloppeInfos} from "./enveloppe.selectors";
import {Store} from "@ngrx/store";

@Injectable()
export class EnveloppeEffects {

  constructor(
      private actions$: Actions,
      private enveloppeService: EnveloppeService,
      private store: Store
  ) {
  }

  load = createEffect(
      () =>
          this.actions$.pipe(
              ofType(loadEnveloppeAction),
              switchMap(action => {
                return this.enveloppeService.getInfosEnveloppe(action.uid)
                    .pipe(
                        map(response => {
                          return loadEnveloppeSuccessAction(response)
                        }),
                        tap(response => {
                          if (!!response.data.session) {
                            this.enveloppeService.setSessionUid(response.data.session.uid, action.uid);
                            return setSessionUid({uid: response.data.session.uid})
                          }
                        }),
                        catchError(err => {
                          return of(loadEnveloppeErrorAction({error: err}))
                        })
                    )
              }),
              catchError(err => {
                return of(loadEnveloppeErrorAction({error: err}))
              })
          ),
      {dispatch: true}
  );

  demandeContactExpiree = createEffect(
      () =>
          this.actions$.pipe(
              ofType(demandeContactEnveloppeExpiree),
              switchMap(action => {
                return this.enveloppeService.demandeContactEnveloppeExpiree(action.uid, action.comment)
                    .pipe(
                        map(response => {
                          return demandeContactEnveloppeExpireeSuccess(null)
                        }),
                        catchError(err => {
                          return of(demandeContactEnveloppeExpireeError(err))
                        })
                    )
              }),
              catchError(err => {
                return of(demandeContactEnveloppeExpireeError(err))
              })
          )
  );

  updateForms = createEffect(
      () =>
          this.actions$.pipe(
              ofType(updateForms),
              switchMap(action => {
                return this.enveloppeService.updateForms(action.data, action.uid)
                    .pipe(
                        map(response => {
                          return updateFormsSuccess({inputData: response.data.formulaires});
                        }),
                        catchError(err => {
                          return of(updateFormsError(err));
                        })
                    )
              }),
              catchError(err => {
                return of(updateFormsError(err));
              })
          )
  )

  fichierRead = createEffect(
      () => this.actions$.pipe(
          ofType(setFichierRead),
          withLatestFrom(this.store.select(selectEnveloppeInfos)),
          filter(([action, infos]) => !!infos && !infos.lecture_seule_ac && !action.fichier.lu),
          switchMap(([action, _]) => {
            return this.enveloppeService.setFichierRead(action.uid, action.fichier.id)
          })
      ),
      {dispatch: false}
  );

  fichierOpened = createEffect(
      () => this.actions$.pipe(
          ofType(setFichierOpened),
          withLatestFrom(this.store.select(selectEnveloppeInfos)),
          filter(([action, infos]) => !!infos && !infos.lecture_seule_ac && !action.fichier.ouvert),
          switchMap(([action, _]) => {
            return this.enveloppeService.setFichierOpened(action.uid, action.fichier.id)
          })
      ),
      {dispatch: false}
  );
}
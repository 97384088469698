<div class="document-viewer">

  <div class="w-100">
    <div class="ac-back-to mr-auto d-flex align-items-center" (click)="back()">
      <mat-icon class="mr-3">arrow_back</mat-icon>
      <p class="mb-0">{{'ac.global.previous-page' | translate}}</p>
    </div>
  </div>

  <div class="row doc-container">
    <div class="col col-md-4">
      <div class="w-100 title">


        <h4>{{subtitle}}</h4>
        <div class="d-flex align-items-center justify-content-start">
          <ac-primary-title [title]="title"></ac-primary-title>
        </div>
      </div>


      <div *ngFor="let btn of getTopActionButtons()" class="w-100 btn-container">
        <ac-button
                *ngIf="(btn.isDownloadBtn && pdfList.length === 0) || !btn.isDownloadBtn"
        [label]="btn.label"
        [hidden]="btn.isDownloadBtn && (loading$ | async)"
        [suffixIcon]="btn.icon" (onClick)="!!btn.isDownloadBtn ? triggerDownload() : onAction.emit(btn.actionId)" [bgColor]="btn.color ?? '#b32826'">
        </ac-button>

        <mat-spinner [diameter]="40" *ngIf="btn.isDownloadBtn" [hidden]="!(loading$ | async)"></mat-spinner>
      </div>


      <div class="w-100 date-container" [hidden]="!!!createdAt">
        <h4>{{ 'ac.home.documents.created-at' | translate }}</h4>
        <p>{{createdAt  | acDate |  date:'dd/MM/yyyy'}}</p>
        <h4 *ngIf="element?.id_file_source">{{ 'ac.home.cee.document-annule-et-remplace' | translate }}</h4>
        <ac-button
                [label]="'ac.home.cee.voir-document-source' | translate"
                [hidden]="!element?.id_file_source"
                (onClick)="onAction.emit('gotoDocSource')"
                [bgColor]="'#b32826'">
        </ac-button>
      </div>


      <div *ngIf="statusType != 'none'" class="w-100 status-container">
        <h4>STATUT</h4>

        <span class="{{statusType}}">
          <mat-icon>{{statusIcon}}</mat-icon>
        </span>
      </div>

      <div *ngFor="let btn of getBottomActionButtons()" class="w-100 btn-container">
        <ac-button
                *ngIf="(btn.isDownloadBtn && pdfList.length === 0) || !btn.isDownloadBtn"
                [label]="btn.label"
                [hidden]="btn.isDownloadBtn && (loading$ | async)"
                [suffixIcon]="btn.icon" (onClick)="!!btn.isDownloadBtn ? triggerDownload() : onAction.emit(btn.actionId)" [bgColor]="btn.color ?? '#b32826'">
        </ac-button>

        <mat-spinner [diameter]="40" *ngIf="btn.isDownloadBtn" [hidden]="!(loading$ | async)"></mat-spinner>
      </div>

      <div class="files-list mt-5" *ngIf="getNombreFichiersDossiers() > 0">
        <h4>Documents complémentaires</h4>
        <ng-container *ngFor="let dossier of documentDetails.dossiers">
          <h5
                  class="additionnal-file"
                  *ngFor="let fichier of dossier.fichiers"
                  (click)="downloadFichierDossier(dossier.nom, fichier.nom)"
          >
            <span>{{fichier.nom}}</span>
            <mat-icon>vertical_align_bottom</mat-icon>
          </h5>
        </ng-container>
      </div>

    </div>
    <div class="col">
      <div *ngIf="!!pdfInitData && pdfList.length === 0" class="ac-pdf-viewer">
        <pdf-viewer [src]="pdfInitData" [render-text]="true" [original-size]="false"
                    style="width: 100%; height: 100%; min-height: 750px;" ></pdf-viewer>
      </div>
      <ng-content select="[subtitle]"></ng-content>
      <ng-content select="[viewerAction]"></ng-content>
      <div class="mb-4" *ngFor="let pdf of pdfList">
        <h4
                class="download-title title mb-1"
                (click)="downloadFichierClasseur(pdf.pdfSource.url)">
          <span>{{pdf.intitule}}</span>
          <mat-icon>vertical_align_bottom</mat-icon>
        </h4>
        <div class="ac-pdf-viewer">
          <pdf-viewer [src]="pdf.pdfSource" [render-text]="true" [original-size]="false"
                      style="width: 100%; height: 100%; min-height: 750px;" ></pdf-viewer>
        </div>
      </div>

    </div>
  </div>

</div>

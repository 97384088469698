<div class="message-dialog">
    <div mat-dialog-content>
        <p [innerHTML]="data.message | translate"></p>
    </div>
    <div mat-dialog-actions align="center">
        <button
                mat-raised-button
                color="primary"
                (click)="clickConfirm()"
        >
            <mat-icon matPrefix>check</mat-icon>
            {{ data.buttonText | translate}}
        </button>
    </div>
</div>

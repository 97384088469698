import {Component, EventEmitter, Input, Output} from '@angular/core';
import {of} from "rxjs";

@Component({
  selector: 'app-mobile-bottom-toolbar',
  templateUrl: './mobile-bottom-toolbar.component.html',
  styleUrls: ['./mobile-bottom-toolbar.component.scss']
})
export class MobileBottomToolbarComponent {
  @Input()
  leftButtonLabel: string = '';

  @Input()
  rightButtonLabel: string = '';

  @Input()
  rightButtonLoading$ = of(false);

  @Input()
  leftButtonLoading$ = of(false);

  @Input()
  rightButtonDisabled: boolean = false;

  @Input()
  leftButtonDisabled: boolean = false;

  @Output()
  onLeftButtonClick = new EventEmitter<any>();

  @Output()
  onRightButtonClick = new EventEmitter<any>();

}

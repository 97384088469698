import {Component, Input} from '@angular/core';
import {SimpleTextIconListEntry} from "./core/simple-text-icon-list.models";

@Component({
  selector: 'app-simple-text-icon-list',
  templateUrl: './simple-text-icon-list.component.html',
  styleUrls: ['./simple-text-icon-list.component.scss']
})
export class SimpleTextIconListComponent {

  /**
   * Les lignes à afficher
   * @var string text : Le texte à afficher
   * @var string|null icon : le nom de l'icone à afficher, null pour aucune icone
   * @var function|null action : fonction à déclencher au clic sur la ligne, null si ne rien faire
   */
  @Input()
  entries: SimpleTextIconListEntry[]
}
